<script>
  import { mapActions } from 'vuex';
  export default {
    data() {
      return {
        isLoading: false,
      };
    },
    props: {
      course: {
        type: Object,
        required: true,
      },
    },
    methods: {
      ...mapActions('CoursesModule', ['completeCourse']),
      ...mapActions(['setErrorSnackbarMessage']),
      async submitCompleteCourse() {
        this.isLoading = true;
        try {
          await this.completeCourse(this.course.id);
          this.$emit('completeCourse');
        } catch (error) {
          this.setErrorSnackbarMessage(error);
        } finally {
          this.isLoading = false;
        }
      },
    },
    computed: {
      isCompleted() {
        return this.course.progress === 100;
      },
    },
  };
</script>
