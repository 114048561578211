<template>
  <div>
    <v-btn
      v-if="!isCompleted"
      class="course-complete-button py-5"
      outlined
      dark
      color="primaryColor"
      @click="submitCompleteCourse"
      :loading="isLoading">
      Complete
    </v-btn>
    <v-btn
      v-else
      class="course-complete-button py-5 white--text"
      color="primaryColor"
      depressed
      >Complete <v-icon>mdi-check</v-icon></v-btn
    >
  </div>
</template>

<script>
  import CompleteButtonMixin from '@/components/courses/course-details/complete-button/CompleteButton.mixin.vue';
  export default {
    mixins: [CompleteButtonMixin],
  };
</script>

<style lang="scss" scoped>
  .course-complete-button {
    border: 2px solid var(--v-primaryColor-base) !important;
    border-radius: 28px !important;
    font-weight: 600;
  }
</style>
